import React from 'react';
import blueCheckIcon from '../../images/contact-sales/bluecheck.svg';
import TestimonyBlockquote from '../testimonies/TestimonyBlockquote';
import CompanyLogos from './CompanyLogos';
import styles from './ContactSalesContent.module.scss';
import ContactSalesForm from './ContactSalesForm';

const Check: React.SFC = ({ children }) => (
  <li className={styles.checkListItem}>
    <img src={blueCheckIcon} alt="Check" />
    {children}
  </li>
);

const ContactSalesContent: React.SFC = () => (
  <div className={styles.contentWrap}>
    <div className={styles.grid}>
      <div className={styles.intro}>
        <h1 className={styles.desktop}>Contact Sales</h1>
        <h1 className={styles.mobile}>Contact Sales</h1>
        <p className={`${styles.introText} ${styles.desktop}`}>
          Talk with our team to learn how to:
        </p>
        <p className={`${styles.introTextMobile} ${styles.mobile}`}>
          Learn how to
        </p>
        <ul className={styles.checkList}>
          <Check>Create content that looks good by default.</Check>
          <Check>Make it easy for teammates to browse and discover.</Check>
          <Check>
            Learn about unified search, pulling your answers into one.
          </Check>
        </ul>
      </div>
      <div className={styles.companyLogos}>
        <CompanyLogos />
        <TestimonyBlockquote small slug="scott" />
      </div>
      <div className={styles.salesForm}>
        <div className={styles.salesFormBorder}>
          <ContactSalesForm />
        </div>
      </div>
    </div>
  </div>
);

export default ContactSalesContent;
