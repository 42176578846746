import React from 'react';

interface ExternalLogoLinkProps {
  className?: string;
  name: string;
  link: string;
  logo: string;
}

const ExternalLogoLink: React.SFC<ExternalLogoLinkProps> = ({
  className,
  name,
  link,
  logo,
}) => (
  <div className={className}>
    {/* eslint-disable-next-line react/jsx-no-target-blank */}
    <a href={link} target="_blank" rel="noopener">
      <img alt={name} src={logo} loading="lazy" />
    </a>
  </div>
);

export default ExternalLogoLink;
