import React from 'react';
import ContactSalesContent from '../../components/contact-sales/ContactSalesContent';
import MarketingLayout from '../../components/layout/MarketingLayout';
import PageSection from '../../components/ui/PageSection';
import Spacing from '../../components/ui/Spacing';

const NewContactSalesPage: React.SFC = () => (
  <MarketingLayout title="Contact Sales">
    <PageSection>
      <Spacing bottom={5}>
        <ContactSalesContent />
      </Spacing>
    </PageSection>
  </MarketingLayout>
);

export default NewContactSalesPage;
