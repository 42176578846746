import { navigate } from 'gatsby';
import React from 'react';
import submitForm from '../../utils/submitForm';
import Form, { Field, Submit } from '../ui/Form';
import Spacing from '../ui/Spacing';

const IS_CALENDLY_ENABLED = false;
const IGNORED_SUFFIXES = ['.edu', '.gov', '.org'];
type ContactSalesFormValues = {
  name: string;
  email: string;
  message: string;
  seats?: number;
};

const shouldShowCalendly = ({ email }: ContactSalesFormValues) => {
  if (!IS_CALENDLY_ENABLED) {
    return false;
  }
  if (IGNORED_SUFFIXES.some((ending) => email.endsWith(ending))) {
    return false;
  }

  return true;
};

const handleSubmit = (values: ContactSalesFormValues) =>
  submitForm<ContactSalesFormValues>(
    process.env.FORM_ID_REQUEST_DEMO,
    values,
  ).then(() => {
    window.analytics.track('sales_contact', {
      category: 'site',
      email: values.email,
      seats: values.seats,
    });
    if (shouldShowCalendly(values)) {
      window.open(
        `https://calendly.com/slabhq/sales?name=${encodeURIComponent(
          values.name,
        )}&email=${encodeURIComponent(values.email)}`,
      );
      navigate('/');
    } else {
      navigate(`${window.location.pathname}confirmation`);
    }
  });

const searchParams = new URLSearchParams(
  typeof window !== 'undefined' ? window.location.search : '',
);

const ContactSalesForm: React.SFC = () => (
  <Form
    initialValues={{
      name: searchParams.get('name') || '',
      email: searchParams.get('email') || '',
      message: '',
    }}
    onSubmit={handleSubmit}
    validateOnBlur
    validateOnChange
  >
    <Field
      name="name"
      label="Full name"
      type="text"
      required
      placeholder="Gavin Belson"
    />
    <Field
      name="email"
      label="Email address"
      type="email"
      required
      autoComplete="email"
      placeholder="gavin@hooli.com"
    />
    <Field
      name="seats"
      label="How many seats do you expect to need?"
      type="number"
      placeholder="248"
      min={0}
      required
    />
    <Field
      name="message"
      label="What are the top things you'd like to learn about?"
      type="textarea"
      required
      placeholder="I'd like to learn about..."
    />
    <Spacing top={2}>
      <Submit block disableUntilValid color="red">
        Send Request
      </Submit>
    </Spacing>
  </Form>
);

export default ContactSalesForm;
